import * as React from 'react';
import { Dialog } from '@mui/material';
import AppButton from './AppButton';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../firebase-config';
import boyIcon from '../../assets/icons/boy.svg';
import girlIcon from '../../assets/icons/girl.svg';


// In SliderSizes.js
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';


export const DataCollectionPopup = ({
    mode,
    round,
    StartGame,
    setShowDataPopup,
    showDataPopup,
    childId,
    tournamentId,
    registrations,
    poolIds,
    avgScores,
    user,
}) => {
    // const round = 3;
    const [sliderValue, setSliderValue] = React.useState(0);


    //user poolId is the common id in both the registrations array and the poolIds array


    const userPoolId = registrations?.find((poolId) => poolIds?.includes(poolId));


    const getHeader = (mode, round) => {
        switch (mode) {
            case 'treatment0':
                return 'Predict your score in this round';
                break;
            case 'treatment1':
                return 'Predict your score in this round ';
                break;
            case 'treatment2':
                return 'Predict your score in this round ';
                break;
            case 'treatment3':
                return 'Predict your score in this round ';
                break;
        }
    };




    const getBody = (mode, round, avgScores, userPoolId) => {
        switch (mode) {
            case 'treatment0':
                return <div></div>;


            case 'treatment1':
                if (round === 1) return <div></div>;


                if (round === 2 || round === 3) {
                    return (
                        <div className='text-[14px] text-center flex flex-col items-center gap-2'>
                            <span>
                                Last round’s average score for all players was
                            </span>
                            <span className='text-[16px] font-bold'>
                                {avgScores?.[round - 1]?.['Total']}
                            </span>


                        </div>
                    );
                }
                return <div></div>;


            case 'treatment2':
                if (round === 1) return <div></div>;


                if (round === 2 || round === 3) {
                    return (
                        <div clasName="flex flex-col items-center gap-2 text-[14px]">
                            <div className='text-[14px]'>
                                {`Last round’s average score for ${user?.gender == "M" ? 'boys' : 'girls'} was`}


                            </div>
                            <div className='flex items-center w-full justify-center gap-2 mt-2'>
                                <img
                                    src={user?.gender == "M" ? boyIcon : girlIcon}
                                    alt="icon"
                                    style={{ width: '40px', height: '40px' }}
                                />


                                <span className="ml-2">
                                    {avgScores?.[round - 1]?.[user?.gender]}
                                </span>
                            </div>
                        </div>
                    );
                }


                return <div></div>;


            case 'treatment3':
                if (round === 1) return <div></div>;


                if (round === 2 || round === 3) {
                    return (
                        <div className="flex flex-col items-center gap-4 text-[14px]">
                            <div >
                                {`Last round’s average score was`}
                            </div>
                            <div className='flex items-between gap-8'>
                                <div className='flex items-center'>
                                    <img
                                        src={boyIcon}
                                        alt="icon"
                                        style={{ width: '40px', height: '40px' }}
                                    />


                                    <span className="ml-2">
                                        {avgScores?.[round - 1]?.['M']}
                                    </span>
                                </div>
                                <div className='flex items-center'>


                                    <img
                                        src={girlIcon}
                                        alt="icon"
                                        style={{ width: '40px', height: '40px' }}
                                    />


                                    <span className="ml-2">
                                        {avgScores?.[round - 1]?.['F']}
                                    </span>
                                </div>
                            </div>
                        </div>
                    );
                }
                return <div></div>;


            default:
                return <div></div>;
        }
    };




    const storeResponses = async (inputValue) => {
        const data = {
            mode,
            round,
            childId,
            tournamentId,
            response: inputValue ?? sliderValue,
            createdAt: new Date()
        };
        const ref = await addDoc(collection(db, 'researchData'), data);
    };




    return (
        <Dialog
            open={showDataPopup}
            onClose={() => {
                setShowDataPopup(false);
                storeResponses();
                StartGame();
            }}
            PaperProps={{
                sx: {
                    m: 0,
                    p: 0,
                    background: 'none',
                    boxShadow: 'none',
                    borderRadius: 0
                }
            }}
        >
            <div className="relative flex flex-col justify-center items-center bg-primary-gradient text-white h-full gap-6 rounded-lg">
                <div className='flex flex-col items-center gap-2'>
                    <span className="text-lg md:text-xl font-medium text-center text-[#ccf900] font-bold mt-6">
                        Bonus Challenge
                    </span>
                    <span className="text-[14px] text-center width-[80%] font-light mb-4">
                        Correct answers get a chance to win a prize!
                    </span>
                </div>


                {getBody(mode, round, avgScores)}


                <span className="text-[16px] text-center font-bold mt-2">
                    {getHeader(mode, round)}
                </span>

                <div className="flex flex-col items-center mt-2">
                    <SliderSizes sliderValue={sliderValue} setSliderValue={setSliderValue} />
                </div>

                <div className="flex flex-col items-center gap-4">
                    <AppButton
                        onClick={() => {
                            storeResponses();
                            setShowDataPopup(false);
                            StartGame();
                        }}
                        className="rounded-[115px] min-w-[159px] w-[159px] h-[35px] min-h-[35px] self-center"
                    >
                        Proceed
                    </AppButton>


                    <span className="text-[12px] text-center mb-4 underline"
                        onClick={() => {
                            setShowDataPopup(false);
                            storeResponses(-1);
                            StartGame();
                        }}
                    >
                        Skip
                    </span>
                </div>
            </div>
        </Dialog>
    );
};

export default function SliderSizes({ sliderValue, setSliderValue }) {
    return (
        <div className="flex flex-col items-center gap-2">
            <div className="flex flex-row items-center gap-4">
                <span className="text-[12px]">0</span>
                <Box sx={{ width: '150px' }}>
                    <PrettoSlider
                        value={sliderValue}
                        onChange={(e, newValue) => setSliderValue(newValue)}
                        valueLabelDisplay="on"
                        max={30}
                        defaultValue={20}
                    />
                </Box>
                <span className="text-[12px]">30 +</span>
            </div>
            <span className="text-[12px]">Drag the slider to change your prediction</span>
        </div>
    );
}

const PrettoSlider = styled(Slider)({
    color: '#ccf900',
    '& .MuiSlider-valueLabel': {
        backgroundColor: '#3a3a3a',
        color: '#ccf900',
        borderRadius: '4px',
        fontSize: '12px',
    },
});